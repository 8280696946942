import "styles/pages/page-monuments.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import MonumentsMap from "../components/MonumentsMap";
import Breadcrumbs from "../components/Breadcrumbs";

const MonumentsPage = ({ pageContext }) => {
	const pageMonuments = pageContext.pageContent;
	return (
		<Layout headerColor="#FFCE2E">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/monuments-img-1.png").default}
			/>
			<Breadcrumbs currentLocationLabel="Zabytki" />
			<section className="page-main-monuments">
				<div className="container">
					<h2
						className="section-title"
						dangerouslySetInnerHTML={{
							__html: pageMonuments.monumentsTitle,
						}}
					></h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<div
								className="page-main-monuments__text"
								dangerouslySetInnerHTML={{
									__html: pageMonuments.monumentsDesc,
								}}
							></div>
							{/* <img src={require("assets/images/static-monuments-map.png").default} alt="" className="img-fluid page-main-monuments__img page-main-monuments__img--1" /> */}
							<MonumentsMap className="page-main-monuments__map" />
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageMonuments.monumentsImg1?.sourceUrl}
								alt=""
								className="img-fluid page-main-monuments__img page-main-monuments__img--2"
							/>
							<img
								src={pageMonuments.monumentsImg2?.sourceUrl}
								alt=""
								className="img-fluid page-main-monuments__img page-main-monuments__img--3"
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default MonumentsPage;
